import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";

import { SmartrrAction, SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadCustomerPortalTheme =
  (): SmartrrThunkAction<
    "LOADING_CUSTOMER_PORTAL_THEME" | "ERROR_LOADING_CUSTOMER_PORTAL_THEME" | "LOADED_CUSTOMER_PORTAL_THEME"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/customer-portal-theme"),
      () =>
        dispatch({
          type: "LOADING_CUSTOMER_PORTAL_THEME",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMER_PORTAL_THEME",
          payload: {
            errorMessage: failure.message,
          },
        }),
      customerPortalTheme =>
        dispatch({
          type: "LOADED_CUSTOMER_PORTAL_THEME",
          payload: {
            customerPortalTheme,
          },
        })
    );

export const updateCustomerPortalTheme =
  ({
    updatedThemeProperties,
    themeId,
    addToast,
  }: {
    updatedThemeProperties: Partial<ICustomerPortalTheme>;
    themeId: number;
    addToast: (content: string) => void;
  }): SmartrrThunkAction<
    "UPDATING_CUSTOMER_PORTAL_THEME" | "ERROR_UPDATING_CUSTOMER_PORTAL_THEME" | "UPDATED_CUSTOMER_PORTAL_THEME"
  > =>
  async dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/customer-portal-theme", {
        reqBody: {
          themeShopifyId: themeId,
          updatedThemeProperties,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_CUSTOMER_PORTAL_THEME",
          payload: undefined,
        }),
      failure => {
        addToast("Error updating theme");
        return dispatch({
          type: "ERROR_UPDATING_CUSTOMER_PORTAL_THEME",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      customerPortalTheme => {
        addToast("Theme updated");
        return dispatch(updatedCustomerPortalTheme(customerPortalTheme));
      }
    );

export const updatedCustomerPortalTheme = (
  customerPortalTheme: ICustomerPortalTheme
): SmartrrAction<"UPDATED_CUSTOMER_PORTAL_THEME"> => ({
  type: "UPDATED_CUSTOMER_PORTAL_THEME",
  payload: {
    customerPortalTheme,
  },
});
